import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../assets/logo.png';

function AppHeader() {
  return (
    <section>
    <Navbar bg="light" expand="lg">
      <Container>
        <Navbar.Brand href="/">
        <img className='logoHome' src={Logo} alt="Minha Imagem" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link  style={{textDecoration: 'none',fontSize: '20px'}} href="/">Home</Nav.Link>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-ispa"
              title="A Instituição"
              menuVariant=""
            >
              <NavDropdown.Item className='item_nav' href="/instituicao"><i class="fa-solid fa-building-columns"></i>    Apresentação</NavDropdown.Item>
              <NavDropdown.Item href="/palavra_do_presidente" ><i class="fa-solid fa-building-columns"></i> Palavra do Presidente</NavDropdown.Item>
              <NavDropdown.Item href="/organigrama" ><i class="fa-solid fa-building-columns"></i> Organigrama</NavDropdown.Item>
              <NavDropdown.Item href="/missao_visao_valores" ><i class="fa-solid fa-building-columns"></i> Missão Valores e Visão</NavDropdown.Item>
              <NavDropdown.Item href="/apoioSocial" ><i class="fa-solid fa-building-columns"></i> Açção Social</NavDropdown.Item>
              <NavDropdown.Item href="/perguntas_frequentes" ><i class="fa-solid fa-building-columns"></i> Perguntas Frequentes</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" ><i class="fa-solid fa-building-columns"></i> Projectos</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-ispa"
              title="Internacional"
              menuVariant=""
            >
              <NavDropdown.Item className='item_nav' href="/internacional" ><i class="fa-solid fa-globe"></i> Porque Ispatlantida?</NavDropdown.Item>
              <NavDropdown.Item href="/estudante-internacional" > <i class="fa-solid fa-globe"></i> Estudante Internacional</NavDropdown.Item>
              <NavDropdown.Item href="/alojamento" style={{marginLeft: '1px'}} > <i class="fa-solid fa-globe"></i> Alojamento</NavDropdown.Item>
              <NavDropdown.Item href="/preços" style={{marginLeft: '1px'}} > <i class="fa-solid fa-globe"></i> Preçarios</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Programa"
              menuVariant=""
            >
              <NavDropdown.Item href="/"><i class="fas fa-tasks-alt"></i> Licenciatura</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fas fa-tasks-alt"></i> Cursos Profissionais</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fas fa-tasks-alt"></i> Pós-Graduação</NavDropdown.Item>
              <NavDropdown.Item href="/unidade_organica" ><i class="fas fa-tasks-alt"></i> Unidade Orgânica</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" ><i class="fas fa-tasks-alt"></i> Unidade Currilares</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Investigação"
              menuVariant=""
            >
              <NavDropdown.Item href="/"><i class="fas fa-warehouse"></i> Investigação Científica</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fas fa-warehouse"></i> Unidade de Investigação</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fas fa-warehouse"></i> Publicação</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fas fa-warehouse"></i> Laboratório</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fas fa-warehouse"></i> Apoio a Investigação</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" ><i class="fas fa-warehouse"></i> Revista Olhar</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Recursos"
              menuVariant=""
            >
              <NavDropdown.Item href="/"> <i class="fa-regular fa-calendar-days"></i> Calendário Acadêmicos</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fa-regular fa-calendar-days"></i> Calendário Científico</NavDropdown.Item>
              <NavDropdown.Item href="/" ><i class="fa-regular fa-calendar-days"></i> Regulamento de Estudante</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/" ><i class="fa-regular fa-calendar-days"></i> Biblioteca Digital</NavDropdown.Item>
            </NavDropdown>
            <NavDropdown style={{textDecoration: 'none',fontSize: '20px'}}
            className='dropdown'
              id="nav-dropdown-dark-example"
              title="Comunicação"
              menuVariant=""
            >
              <NavDropdown.Item href="/anuncio"><i class="fa-regular fa-calendar-check"></i> Eventos</NavDropdown.Item>
              <NavDropdown.Item style={{marginLeft: '1px'}} href="/noticia" ><i class="fa-regular fa-calendar-check"></i> Noticias</NavDropdown.Item>
            </NavDropdown>
            <Nav.Link href="https://portalonline.icsgisabelinha.com/" target='_blank'  style={{textDecoration: 'none',fontSize: '20px'}}> Portal Online </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </section>
  );
}

export default AppHeader;